<template>
  <div>
    <a-select
      v-model:value="propertyIds"
      :placeholder="$t('Select property')"
      :filter-option="filterOption"
      size="large"
      option-filter-prop="key"
      mode="multiple"
      @change="handleChange"
    >
      <a-select-option
        v-for="property in properties"
        :key="property.newPropertyCode"
        :value="property.newPropertyCode"
      >
        {{ property.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n src="@/locales/components/add-upsell-items.json"></i18n>

<script>
export default {
  name: 'InputPropertyGroup',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      properties: [],
      propertyIds: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.propertyIds = nv;
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = { infinite: true };

      if (value.length > 0) {
        params.name = value;
      }

      if (this.partnerId) {
        params.partnerId = this.partnerId;
      }

      return this.$store.dispatch('properties/names', params).then((res) => {
        if (this.neppanOnly) {
          this.properties = res.list.filter((p) => p.neppanPropertyCode);
        } else {
          this.properties = res.list;
        }
        return Promise.resolve();
      });
    },
    filterOption(inputValue, { children }) {
      return children.find(
        (child) => child.children.toLowerCase().includes(inputValue.toLowerCase()),
      );
    },
    handleChange(value) {
      this.propertyIds = value;
      const selected = this.properties.find((p) => p.newPropertyCode === value);
      this.$emit('update:value', this.propertyIds);
      this.$emit('change', selected);
    },
  },
};
</script>

<style>
</style>
