<template>
  <div>
    <a-select
      v-model:value="providerIds"
      show-search
      :placeholder="$t('Select provider')"
      :filter-option="filterOption"
      style="width: 100%"
      dropdown-class-name="property-max-content-width"
      size="large"
      mode="multiple"
      @change="handleChange"
    >
      <a-select-option
        v-for="provider in providers"
        :key="provider.id"
        :value="provider.id"
      >
        {{ provider.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { RELATIONSHIPS } from '@/config/partners';

export default {
  name: 'InputProviderGroup',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      providers: [],
      providerIds: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.providerIds = nv;
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      const params = { infinite: true, relationship: RELATIONSHIPS.UPSELL_ITEM_PROVIDER };

      if (value.length > 0) {
        params.name = value;
      }

      return this.$store.dispatch('partners/names', params).then((res) => {
        this.providers = res.list;
        return Promise.resolve();
      });
    },
    filterOption(inputValue, { children }) {
      return children.find(
        (child) => child.children.toLowerCase().includes(inputValue.toLowerCase()),
      );
    },
    handleChange(value) {
      this.providerIds = value;
      const selected = this.providers.find((p) => p.id === value);
      this.$emit('update:value', this.providerIds);
      this.$emit('change', selected);
    },
  },
};
</script>

<style>
</style>
