<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Upsell Items') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="columns is-mobile m-0">
        <div class="column pt-0 pb-0 pl-0">
          <SearchForm @filter="fetchData" />
        </div>
        <div class="column is-narrow p-0">
          <ModalWide ref="editItem" width="90%">
            <template #default>
              <AddUpsellItemForm
                :value="selectedItem"
                @close="
                  () => {
                    resetField();
                    fetchData();
                  }
                "
                @cancel="resetField"
              />
            </template>
            <template #handler="{ show }">
              <a class="ant-btn ant-btn-lg ant-btn-primary" @click="show">
                {{ $t('New Upsell Item') }}
              </a>
            </template>
          </ModalWide>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="upsells"
        :loading="isFetching"
        :pagination="pagination"
        :custom-row="rowClicked"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        row-key="id"
        @change="handleTableChange"
      >
        <template #title="{ record }">
          {{ getTitle(record?.title) }}
        </template>
        <template #itemId="{ text }">
          {{ text }}
        </template>
        <template #providers="{ record }">
          {{ joinProviders(record.providers) }}
        </template>
        <template #is_active="{ record }">
          {{ $t(getStatus(record.isActive)) }}
        </template>
        <template #price="{ record }">
          {{ $filters.number(record?.price) }}
        </template>
        <template #action="{ record }">
          <div class="is-flex is-justify-content-flex-end">
            <Delete
              action-handler="upsell-items/del"
              :payload-value="{ id: record.id }"
              :title="$t('Delete title')"
              :description="$t('Description', { title: getTitle(record?.title) })"
              :success-title="$t('Successfully deleted title')"
              :success-description="$t('Successfully deleted description',
                                       { title: getTitle(record?.title) })"
              @close="fetchData"
            />
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<i18n src="@/locales/components/add-upsell-items.json"></i18n>

<script>
import pagination from '@/components/mixins/pagination';
import SearchForm from '@/views/upsell-items/components/SearchForm';
import Delete from '@/components/PopupDelete';
import ModalWide from '@/components/ModalWide';
import AddUpsellItemForm from '@/views/upsell-items/components/AddUpsellItemForm';

export default {
  name: 'UpsellItems',
  components: {
    AddUpsellItemForm,
    ModalWide,
    SearchForm,
    Delete,
  },
  mixins: [pagination],
  data() {
    return {
      upsells: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Item Title'),
          dataIndex: 'title',
          key: 'title',
          slots: { customRender: 'title' },
          width: '25%',
        },
        {
          title: this.$t('Provider item ID'),
          dataIndex: 'itemId',
          key: 'itemId',
          slots: { customRender: 'itemId' },
        },
        {
          title: this.$t('Providers'),
          dataIndex: 'providers',
          key: 'providers',
          slots: { customRender: 'providers' },
          width: '20%',
        },
        {
          title: this.$t('Status'),
          dataIndex: 'is_active',
          key: 'is_active',
          slots: { customRender: 'is_active' },
        },
        {
          title: this.$t('Price'),
          key: 'price',
          dataIndex: 'price',
          slots: { customRender: 'price' },
        },
        {
          title: ' ',
          key: 'action',
          width: '200px',
          slots: { customRender: 'action' },
        },
      ],
      selectedItem: undefined,
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'upsell-items') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('upsell-items/list', this.$route.query)
        .then((resp) => {
          const { data } = resp;
          const { rows, pagination } = data;
          this.upsells = rows;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    joinProviders(providers) {
      return providers.map((provider) => provider.name).join(', ');
    },
    getStatus(isActive) {
      return isActive ? 'active' : 'inactive';
    },
    getTitle(title) {
      if (title && typeof title === 'string') {
        return title;
      }

      if (this.$i18n.locale === 'ja') {
        return title?.ja;
      }

      return title?.en;
    },
    rowClicked(record) {
      return {
        onClick: async () => {
          this.selectedItem = {
            id: record.id,
            itemId: record.itemId,
            title: { ...record.title },
            cancellationInformation: { ...record.cancellationInformation },
            category: record.category,
            description: { ...record.description },
            isActive: record.isActive,
            leadTime: record.leadTime,
            minItemsPerOrder: record.minItemsPerOrder,
            maxItemsPerOrder: record.maxItemsPerOrder,
            otherInformation: { ...record.otherInformation },
            price: record.price,
            properties: record.properties.map((item) => item.propertyId),
            providers: record.providers.map((item) => item.providerId),
            image: record.image,
            providerPrice: record.providers[0]?.providerPrice || null,
          };
          this.$refs.editItem.show();
        },
      };
    },
    resetField() {
      this.$refs.editItem.hide();
      this.selectedItem = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-tbody > tr > td {
    border-color: transparent;
  }
}
</style>
