<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('Select provider')"
    style="width: 100%"
    dropdown-class-name="property-max-content-width"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="null"
    :size="size"
    :allow-clear="isAllowClear"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d[returnValue]" :title="d[displayedText]">
      {{ d[displayedText] }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Select provider": "Select provider"
  },
  "ja": {
    "Select provider": "物件選択"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { RELATIONSHIPS } from '@/config/partners';

export default {
  name: 'InputProvider',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    returnValue: {
      type: String,
      default: 'id',
    },
    displayedText: {
      type: String,
      default: 'name',
    },
    isAllowClear: {
      type: Boolean,
      default: false,
    },
    isEditItem: {
      type: Boolean,
      default: false,
    },
    availableProviders: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  computed: {
    selected() {
      return this.data.find((p) => p.id === this.id);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.id = nv;
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch(value = '') {
      if (this.availableProviders?.length === 0 && !this.id && this.isEditItem) return null;

      const params = { infinite: true, relationship: RELATIONSHIPS.UPSELL_ITEM_PROVIDER };

      if (this.isEditItem) {
        params.ids = this.id
          ? this.availableProviders?.map((provider) => provider.providerId).concat(this.id)
          : this.availableProviders?.map((provider) => provider.providerId);
      }

      if (value.length > 0) {
        params.name = value;
      }

      return this.$store.dispatch('partners/names', params).then((res) => {
        this.data = res.list.map((item) => ({ id: `${item.id}`, name: item.name }));
        return Promise.resolve();
      });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 600),
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.selected);
      this.$emit('update:value', this.id);
    },
  },
};
</script>

<style>
.property-max-content-width {
  min-width: 300px !important;
}
@media screen and (max-width: 320px) {
  .property-max-content-width {
    min-width: auto !important;
  }
}
</style>
