<template>
  <div class="columns form is-mini-gap">
    <div class="column is-2 search-input">
      <a-input
        v-model:value="title"
        :size="size"
        :placeholder="$t('Search Item Title')"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <InputProperty
        v-model:value="propertyId"
        :size="size"
        style="width: 100%"
        is-allow-clear
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <InputProvider
        v-model:value="providerId"
        :size="size"
        style="width: 100%"
        is-allow-clear
        @change="debounceQuery"
      />
    </div>
    <div class="column is-2">
      <a-select
        v-model:value="status"
        :size="size"
        :placeholder="$t('Status')"
        style="width: 100%"
        allow-clear
        @change="debounceQuery"
      >
        <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
          {{ $t(item.label) }}
        </a-select-option>
      </a-select>
    </div>
    <div v-show="propertyId || providerId || status || title" class="column is-2">
      <a-button
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t("Clear") }}
      </a-button>
    </div>
  </div>
</template>

<i18n src="@/locales/components/add-upsell-items.json"></i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import InputProvider from '@/components/InputProvider';

export default {
  name: 'SearchForm',
  components: {
    InputProvider, InputProperty,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      title: undefined,
      propertyId: undefined,
      providerId: undefined,
      status: undefined,
      statusList: [
        { label: 'active', value: 'active' },
        { label: 'inactive', value: 'inactive' },
      ],
    };
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.providerId = this.$route.query.providerId || undefined;
    this.title = this.$route.query.title || undefined;
    this.status = this.$route.query.status
      ? this.$route.query.status.split(',') : undefined;
  },
  methods: {
    debounceQuery: debounce(async function debounceQuery() {
      const querySearch = {};

      if (this.title) {
        querySearch.title = this.title;
      }

      if (this.propertyId) {
        querySearch.propertyId = this.propertyId;
      }

      if (this.providerId) {
        querySearch.providerId = this.providerId;
      }

      if (this.status) {
        querySearch.status = this.status;
      }

      await this.$router.push({
        query: {
          page: 1,
          ...querySearch,
        },
      });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.status = undefined;
      this.title = undefined;
      this.providerId = undefined;
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>
