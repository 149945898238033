{
  "en": {
    "Add Item": "Add Item",
    "Edit Item": "Edit Item",
    "Language": "Language",
    "Item title": "Item title",
    "Enabled": "Enabled",
    "Item description": "Item description",
    "Other information": "Other information",
    "Cancellation information": "Cancellation information",
    "Price Per Unit In JPY": "Price per unit in JPY",
    "Linked properties": "Linked properties",
    "Lead time in days": "Lead time in days",
    "Linked providers": "Linked providers",
    "Items per order": "Items per order",
    "Cancel": "Cancel",
    "Save": "Save",
    "Min": "Min",
    "Max": "Max",
    "Category": "Category",
    "Add a description": "Add a description...",
    "Add a information": "Add a information...",
    "Add a cancellation information": "Add a cancellation information...",
    "Add a item title": "Add a item title...",
    "Food": "Food",
    "Alcohol": "Alcohol",
    "Drinks": "Drinks",
    "Souvenir": "Souvenir",
    "Other": "Other",
    "Change": "Change",
    "English Item Title is required": "English Item Title is required!",
    "Japanese Item Title is required": "Japanese Item Title is required!",
    "English Description is required": "English Description is required!",
    "Japaneses Description is required": "Japaneses Description is required!",
    "English Cancellation information is required": "English Cancellation information is required!",
    "Japanese Cancellation information is required": "Japanese Cancellation information is required!",
    "Price is required": "Price is required!",
    "Min Items Per Order is required": "Min Items Per Order is required!",
    "Max Items Per Order is required": "Max Items Per Order is required!",
    "Please input a number": "Please input a number",
    "Character should be less than 2000": "Character should be less than 2000",
    "Character should be greater than 0 and less than 2000": "Character should be greater than 0 and less than 2000",
    "Character should be greater than 10 and less than 100": "Character should be greater than 10 and less than 100",
    "Number should be less than 100": "Number should be less than 100",
    "Provider item ID": "Provider item ID",
    "Provider item ID should be less than 50": "Provider item ID should be less than 50",
    "Add provider item ID": "Add provider item ID...",
    "Add price Per Unit In JPY": "Add price Per Unit In JPY...",
    "Add lead time in days": "Add lead time in days...",
    "Price should be greater 100 and less than 100000": "Price should be greater than 100 and less than 100000",
    "Min item per order should be less than max item per order": "Min item per order should be less than max item per order",
    "Successfully create new item": "Successfully create new item",
    "Successfully update item": "Successfully update item",
    "Provider Price": "Provider Price",
    "Provider Price is required": "Provider Price is required",
    "Provider price should be greater than or equal 100 and less than or equal 100000": "Provider price should be greater than or equal 100 and less than or equal 100000",
    "Item Title": "Item Title",
    "New Upsell Item": "Add Item",
    "Upsell Items": "Upsell Items",
    "Providers": "Provider",
    "Price": "Price",
    "Status": "Status",
    "Delete title": "Delete Item?",
    "Description": "Are you sure you want to delete <strong>{title}</strong> item?",
    "Successfully deleted title": "Item is deleted",
    "Successfully deleted description": "Item <strong>{title}</strong> has been removed.",
    "active": "Active",
    "inactive": "Inactive",
    "Clear": "Clear",
    "Search Item Title": "Search Item Title",
    "Select property": "Select property",
    "Select provider": "Select provider"
  },
  "ja": {
    "Add Item": "商品追加する",
    "Edit Item": "商品を更新する",
    "Language": "言語",
    "Item title": "商品タイトル",
    "Enabled": "有効",
    "Item description": "商品詳細",
    "Other information": "その他情報",
    "Cancellation information": "キャンセル情報",
    "Price Per Unit In JPY": "単価 JPY",
    "Linked properties": "販売可能施設",
    "Lead time in days": "手仕舞い日",
    "Linked providers": "商品提供者",
    "Items per order": "注文ごとの商品",
    "Cancel": "キャンセル",
    "Save": "保存",
    "Min": "最小",
    "Max": "最大",
    "Category": "カテゴリー",
    "Add a description": "詳細を追加する...",
    "Add a information": "情報を追加する...",
    "Add a cancellation information": "キャンセル情報を追加する...",
    "Add a item title": "タイトルを追加する...",
    "Food": "飲食",
    "Alcohol": "お酒",
    "Drinks": "飲み物",
    "Souvenir": "お土産",
    "Other": "その他",
    "Change": "変更",
    "English Item Title is required": "英語の商品タイトルが必要です",
    "Japanese Item Title is required": "日本語の商品タイトルが必要です",
    "English Description is required": "英語商品詳細が必要です",
    "Japaneses Description is required": "日本語商品詳細が必要です",
    "English Cancellation information is required": "英語キャンセル情報が必要です",
    "Japanese Cancellation information is required": "日本語キャンセル情報が必要です",
    "Price is required": "単価が必要です",
    "Min Items Per Order is required": "最小数が必要です",
    "Max Items Per Order is required": "最大数が必要です",
    "Please input a number": "数字を入れてください",
    "Character should be less than 2000": "2000文字以内にしてください",
    "Character should be greater than 0 and less than 2000": "0~2000文字にしてください",
    "Character should be greater than 10 and less than 100": "10~100文字にしてください",
    "Number should be less than 100": "100以下の数字にしてください",
    "Provider item ID": "提供者用のID",
    "Provider item ID should be less than 50": "50文字以内にしてください",
    "Add provider item ID": "提供者用のIDを追加",
    "Add price Per Unit In JPY": "単価が必要です",
    "Add lead time in days": "手仕舞い日が必要です",
    "Price should be greater 100 and less than 100000": "単価が100円〜100000円ではないです",
    "Min item per order should be less than max item per order": "最小数は最大数より多いです",
    "Successfully create new item": "追加しました",
    "Successfully update item": "更新しました",
    "Provider Price": "提供者用の単価",
    "Provider Price is required": "提供者用の単価が必要です",
    "Provider price should be greater than or equal 100 and less than or equal 100000": "提供者用の単価が100円〜100000円ではないです",
    "Item Title": "商品タイトル",
    "New Upsell Item": "商品追加する",
    "Upsell Items": "商品",
    "Providers": "提供者用",
    "Price": "単価",
    "Status": "ステータス",
    "Delete title": "商品を削除？",
    "Description": "本当に <strong>{title}</strong> を削除しますか？",
    "Successfully deleted title": "商品削除完了",
    "Successfully deleted description": "商品 <strong>{title}</strong> が削除されました",
    "active": "Active",
    "inactive": "Inactive",
    "Clear": "クリア",
    "Search Item Title": "アイテムタイトル検索",
    "Select property": "物件選択",
    "Select provider": "提供者用選択"
  }
}