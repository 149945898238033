<template>
  <a-form
    ref="form"
    :model="form"
    class="columns is-mini-gap is-multiline is-desktop"
    :rules="rules"
    hide-required-mark
  >
    <div class="column is-12 is-align-items-center is-flex is-justify-content-space-between">
      <h3 class="has-text-weight-bold is-size-4 is-8 popup-title">
        {{ form.id ? $t('Edit Item') : $t('Add Item') }}
      </h3>
      <a-form-item
        class="column is-4 is-flex is-justify-content-flex-end is-align-items-center form-item"
        :label="$t('Enabled')"
        style="width: auto"
      >
        <a-switch v-model:checked="form.isActive" />
      </a-form-item>
    </div>
    <a-form-item class="column is-4 form-item" :label="$t('Language')">
      <a-select v-model:value="language"
                :size="size"
                aria-autocomplete="off"
                autocomplete="off"
      >
        <a-select-option value="en">
          English
        </a-select-option>
        <a-select-option value="ja">
          Japanese
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      class="column is-4 form-item"
      :label="$t('Item title')" name="title"
    >
      <a-input
        v-model:value="title[language]"
        :size="size"
        :placeholder="$t('Add a item title')"
      />
    </a-form-item>
    <a-form-item
      class="column is-4 form-item"
      :label="$t('Provider item ID')"
      name="itemId"
    >
      <a-input
        v-model:value="form.itemId"
        :size="size"
        :controls="false"
        style="width: 100%"
        :placeholder="$t('Add provider item ID')"
      />
    </a-form-item>
    <a-form-item
      class="column is-4 form-item form-text-area"
      :label="$t('Item description')" name="description"
    >
      <a-textarea
        v-model:value="description[language]"
        :size="size"
        :auto-size="false"
        :placeholder="$t('Add a description')"
      />
    </a-form-item>
    <a-form-item
      class="column is-4 form-item form-text-area"
      :label="$t('Other information')"
      name="otherInformation"
    >
      <a-textarea
        v-model:value="otherInformation[language]"
        :size="size"
        :auto-size="false"
        :placeholder="$t('Add a information')"
      />
    </a-form-item>
    <a-form-item
      class="column is-4 form-item form-text-area"
      :label="$t('Cancellation information')"
      name="cancellationInformation"
    >
      <a-textarea
        v-model:value="cancellationInformation[language]"
        :size="size"
        :auto-size="false"
        :placeholder="$t('Add a cancellation information')"
      />
    </a-form-item>
    <a-form-item class="column is-4 form-item">
      <div class="upload-image-box">
        <div class="upload-image-box__default-img">
          <img v-if="!previewImage" src="@/assets/images/union.svg">
          <img v-else :src="previewImage">
        </div>
        <div class="upload-image-box__button">
          <a-upload
            v-show="!form.image || typeof form.image === 'object'"
            v-model:file-list="fileList"
            name="file"
            accept="image/png,image/jpeg"
            list-type="picture-card"
            :multiple="false"
            :before-upload="handleBeforeUploadImage"
            :show-upload-list="false"
            @change="handleImageChange"
          >
            <a-button
              type="primary"
              :class="previewImage ? 'button-secondary-outline button-image' : ''"
              :size="size"
            >
              <template #icon>
                <CameraOutlined v-if="!previewImage" />
                <EditOutlined v-else />
              </template>
              {{ previewImage ? $t('Change') : $t('Add Image') }}
            </a-button>
          </a-upload>
          <a-button
            v-if="previewImage"
            type="button"
            :class="previewImage ? 'button-secondary-outline button-image' : ''"
            :size="size"
            @click="handleImageRemove"
          >
            <template #icon>
              <DeleteOutlined />
            </template>
            Remove
          </a-button>
        </div>
      </div>
    </a-form-item>
    <div class="is-8 is-mini-gap is-multiline column columns mb-0 pr-0 form-item">
      <a-form-item
        class="column is-6 form-item-child"
        :label="$t('Price Per Unit In JPY')"
        name="price"
      >
        <a-input-number
          v-model:value="form.price"
          :size="size"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
          :controls="false"
          style="width: 100%"
          :placeholder="$t('Add price Per Unit In JPY')"
        />
      </a-form-item>
      <a-form-item
        class="column is-6 form-item-child"
        :label="$t('Linked properties')"
        name="properties"
      >
        <InputPropertyGroup v-model:value="form.properties" />
      </a-form-item>
      <a-form-item
        class="column is-6 form-item-child"
        :label="$t('Lead time in days')"
        name="leadTime"
      >
        <a-select
          v-model:value="form.leadTime"
          style="width: 100%"
          :size="size"
          :options="leadTimeInDayOptions"
          :placeholder="$t('Add lead time in days')"
        />
      </a-form-item>
      <a-form-item class="column is-6 form-item-child" :label="$t('Linked providers')">
        <InputProviderGroup v-model:value="form.providers" />
      </a-form-item>
    </div>
    <div class="column is-4 form-item">
      <label class="label-color">
        {{ $t('Items per order') }}:
      </label>
      <div class="is-flex is-justify-content-flex-start">
        <a-form-item
          class="column is-6 is-flex p-0 form-item item-per-order"
          :label="$t('Min')"
          name="minItemsPerOrder"
        >
          <a-input-number
            v-model:value="form.minItemsPerOrder"
            :size="size"
          />
        </a-form-item>
        <a-form-item
          class="column is-6 is-flex is-justify-content-flex-end p-0 form-item item-per-order"
          :label="$t('Max')"
          name="maxItemsPerOrder"
        >
          <a-input-number
            v-model:value="form.maxItemsPerOrder"
            :size="size"
          />
        </a-form-item>
      </div>
    </div>
    <a-form-item class="column is-4 form-item" :label="$t('Category')" style="margin-top: 5px">
      <a-select
        v-model:value="form.category"
        class="select-category"
        :size="size"
        :options="categories"
        @change="handleChange"
      />
    </a-form-item>
    <a-form-item
      class="column is-4 form-item"
      :label="$t('Provider Price')"
      style="margin-top: 5px"
      name="providerPrice"
    >
      <a-input-number
        v-model:value="form.providerPrice"
        :size="size"
        :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        :controls="false"
        style="width: 100%"
        :placeholder="$t('Add provider price')"
        :disabled="!form?.providers?.length"
      />
    </a-form-item>
    <hr style="width: 100%; margin: 18px 13px">
    <div class="column is-12 is-flex is-justify-content-flex-end is-align-items-end form-item">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/add-upsell-items.json"></i18n>

<script>
import { EditOutlined, CameraOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import InputPropertyGroup from '@/views/upsell-items/components/InputPropertyGroup';
import InputProviderGroup from '@/views/upsell-items/components/InputProviderGroup';
import FileUploader from '@/components/mixins/FileUploader';
import { getBase64, isNumeric } from '@/utils/util';
import { leadTimeInDayOptions } from '@/constants/lead-time-in-days';

export default {
  name: 'AddUpsellItemForm',
  components: {
    InputProviderGroup,
    InputPropertyGroup,
    EditOutlined,
    CameraOutlined,
    DeleteOutlined,
  },
  mixins: [FileUploader],
  props: {
    value: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  emits: ['close', 'cancel', 'update:value'],
  data() {
    return {
      form: {
        id: undefined,
        title: {
          en: undefined,
          ja: undefined,
        },
        itemId: undefined,
        description: {
          en: undefined,
          ja: undefined,
        },
        cancellationInformation: {
          en: undefined,
          ja: undefined,
        },
        otherInformation: {
          en: undefined,
          ja: undefined,
        },
        price: 0,
        category: 'food',
        isActive: true,
        properties: [],
        providers: undefined,
        leadTime: 0,
        minItemsPerOrder: 1,
        maxItemsPerOrder: 1,
        providerPrice: 0,
      },
      language: 'en',
      rules: {
        title: [
          {
            trigger: 'submit',
            validator: (_, value) => this.emptyTextValidator(value, 'Item Title is required'),
          },
          {
            trigger: 'change',
            validator: (_, value) => this.textValidator(value, 10, 100),
          },
        ],
        description: [
          {
            trigger: 'submit',
            validator: (_, value) => this.emptyTextValidator(value, 'Description is required'),
          },
          {
            trigger: 'change',
            validator: (_, value) => this.textValidator(value, 20, 2000),
          },
        ],
        cancellationInformation: [
          {
            trigger: 'submit',
            validator: (_, value) => this.emptyTextValidator(value, 'Cancellation information is required'),
          },
          {
            trigger: 'change',
            validator: (_, value) => this.textValidator(value, 20, 2000),
          },
        ],
        otherInformation: [
          {
            trigger: 'change',
            validator: (_, value) => {
              if ((value?.en && value?.en.length > 2000)
              || (value?.ja && value?.ja.length > 2000)) {
                return Promise.reject(new Error(this.$t('Character should be less than 2000')));
              }

              return Promise.resolve();
            },
          },
        ],
        price: [
          {
            trigger: ['change', 'blur'],
            validator: (_, value) => this.formatNumberValidator(value, this.$t('Price is required'), 'price'),
          },
        ],
        minItemsPerOrder: [
          {
            validator: (_, value) => this.formatNumberValidator(value, this.$t('Min Items Per Order is required'), 'minOrder'),
            trigger: 'change',
          },
        ],
        maxItemsPerOrder: [
          {
            validator: (_, value) => this.formatNumberValidator(value, this.$t('Max Items Per Order is required'), 'maxOrder'),
            trigger: 'change',
          },
        ],
        itemId: [
          {
            trigger: 'change',
            validator: (_, value) => {
              if (!value || (value && value.length < 50)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(this.$t('Provider item ID should be less than 50')));
            },
          },
        ],
        providerPrice: [
          {
            trigger: 'change',
            validator: (_, value) => this.formatNumberValidator(value, this.$t('Provider Price is required'), 'providerPrice'),
          },
        ],
      },
      fileList: undefined,
      loading: false,
      image: undefined,
      size: 'large',
      categories: [
        { label: this.$t('Food'), value: 'food' },
        { label: this.$t('Alcohol'), value: 'alcohol' },
        { label: this.$t('Drinks'), value: 'drinks' },
        { label: this.$t('Souvenir'), value: 'souvenir' },
        { label: this.$t('Other'), value: 'other' },
      ],
      previewImage: undefined,
      leadTimeInDayOptions,
    };
  },
  computed: {
    title: {
      get() {
        return this.form.title;
      },

      set(value) {
        this.form.title = value;
      },
    },
    description: {
      get() {
        return this.form.description;
      },
      set(value) {
        this.form.description = value;
      },
    },
    cancellationInformation: {
      get() {
        return this.form.cancellationInformation;
      },
      set(value) {
        this.form.cancellationInformation = value;
      },
    },
    otherInformation: {
      get() {
        return this.form.otherInformation;
      },
      set(value) {
        this.form.otherInformation = value;
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
          this.previewImage = nv.image;
        }
      },
    },
  },
  methods: {
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      this.loading = true;
      try {
        await this.saveUpsellItem({
          ...this.form,
          providerPrice: undefined,
          properties: this.form.properties
            ? this.form.properties.map((id) => ({ propertyId: id }))
            : undefined,
          providers: this.form.providers
            ? this.form.providers.map((id) => ({
              providerId: id,
              providerPrice: this.form.providerPrice,
            }))
            : undefined,
          otherInformation: this.form.otherInformation.en
            ? this.form.otherInformation
            : undefined,
        });

        this.$refs.form.resetFields();
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
    handleBeforeUploadImage(file) {
      const isValidSize = this.checkFileSize(file);
      this.isFileValid = false;
      if (isValidSize) {
        this.fileList = [file];
        this.image = file;
        this.isFileValid = true;
      }

      return false;
    },
    async handleImageChange(info) {
      if (!this.isFileValid) {
        this.fileList = [];
        this.previewImage = undefined;
        return false;
      }
      const fileList = [...info.fileList];

      this.fileList = fileList.slice(-1);

      await getBase64(info.file, (base64Url) => {
        this.previewImage = base64Url;
      });

      return false;
    },
    handleImageRemove() {
      this.image = '';
      this.previewImage = undefined;
    },
    async uploadImage(itemId, image) {
      if (this.previewImage !== undefined && !image?.size) return;

      const imagePayload = {
        id: itemId,
        file: image,
      };

      await this.$store.dispatch('upsell-items/addImages', imagePayload);
    },
    async saveUpsellItem(payload) {
      if (this.form.id) {
        await this.$store.dispatch('upsell-items/update', payload);
        await this.uploadImage(this.form.id, this.image);
        this.$message.success(this.$t('Successfully update item'));
      } else {
        const item = await this.$store.dispatch('upsell-items/add', payload);
        const { data } = item;
        if (data.id || data[0].id) {
          await this.uploadImage(data.id || data[0].id, this.image);
        }

        this.$message.success(this.$t('Successfully create new item'));
      }
    },
    emptyTextValidator(value, message) {
      if (!value?.en) {
        return Promise.reject(new Error(this.$t(`English ${message}`)));
      }
      if (!value?.ja && this.language === 'ja') {
        return Promise.reject(new Error(this.$t(`Japanese ${message}`)));
      }
      return Promise.resolve();
    },
    formatNumberValidator(value, message, field) {
      if (field === 'providerPrice' && !value && !this.form.providers?.length) {
        return Promise.resolve();
      }

      if (!value) {
        return Promise.reject(new Error(message));
      }

      if ((field === 'maxOrder' && value >= 100) || (field === 'minOrder' && value >= 100)) {
        return Promise.reject(new Error(this.$t('Number should be less than 100')));
      }

      if (field === 'price' && (value < 100 || value > 100000)) {
        return Promise.reject(
          new Error(
            this.$t('Price should be greater than or equal 100 and less than or equal 100000'),
          ),
        );
      }

      if (field === 'minOrder' && value > this.form.maxItemsPerOrder) {
        return Promise.reject(new Error(this.$t('Min items per order should be less than max items per order')));
      }

      if (field === 'providerPrice' && (value < 100 || value > 100000)) {
        return Promise.reject(new Error(this.$t('Provider price should be greater than or equal 100 and less than or equal 100000')));
      }

      if (value && isNumeric(value)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(this.$t('Please input a number')));
    },
    textValidator(value, minLength, maxLength) {
      if (value.en && (value.en.length < minLength || value.en.length > maxLength)) {
        return Promise.reject(new Error(this.$t(`Character should be greater than ${minLength} and less than ${maxLength}`)));
      }

      if (value.ja && (value.ja.length < minLength || value.ja.length > maxLength)) {
        return Promise.reject(new Error(this.$t(`Character should be greater than ${minLength} and less than ${maxLength}`)));
      }

      return Promise.resolve();
    },
  },
};
</script>

<style lang="scss" scoped>
.sep {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.upload-image-box{
  border-radius: 10px;
  background: #F1F1F1;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__default-img {
    overflow: hidden;
  }

  &__button {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .ant-btn {
      margin-left: 12px;
    }
  }
}

.upload-image-box__default-img{
  min-width: 100px;
  width: 100px;
  height: 100px;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-color{
  color: rgba(0, 0, 0, 0.85);
}

::v-deep {
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }

  .ant-upload-picture-card-wrapper {
    width: auto!important;
  }

  .column.form-item {
    padding: 10px 12px;
  }

  .form-item-child:first-child {
    padding-right: 12.5px;
  }

  .form-item-child:nth-child(3) {
    padding-right: 12.5px;
    margin-top: 16px;
  }

  .form-item-child:last-child {
    padding-left: 12.5px;
    margin-top: 16px;
  }

  .form-item-child:nth-child(2) {
    padding-left: 12.5px;
  }

  .item-per-order .ant-form-item-label {
    align-items: flex-start;
    padding-top: 12px;
  }

  .item-per-order {
    .ant-form-item-label {
      width: 20%;
    }

    .ant-form-item-control-wrapper {
      width: 80%;
    }

    .ant-input-number {
      width: 100%;
    }

    &:nth-child(2) {
      .ant-form-item-label {
        padding-left: 10px;
      }
    }

    @media screen and (max-width: 1366px) {
      .ant-form-item-label {
        width: 30%;
      }
      .ant-form-item-control-wrapper {
        width: 70%;
      }
    }

    @media screen and (max-width: 1024px) {
      .ant-form-item-label {
        width: 15%;
      }
      .ant-form-item-control-wrapper {
        width: 85%;
      }
    }
  }

  .button-image {
    border-width: 0px;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
  }
}

.popup-title {
  color: #00596C;
  margin-left: 7px;
}

.form-text-area {
  textarea {
    height: 98px;
    resize: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}

@media screen and (max-width: 1024px) {
  .is-4, .is-8 {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ant-form-item.form-item-child {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0;
  }
}
</style>
